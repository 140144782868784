// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faAngleDown      as fasFaAngleDown      } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleLeft      as fasFaAngleLeft      } from "@fortawesome/pro-solid-svg-icons/faAngleLeft";
import { faAngleRight     as fasFaAngleRight     } from "@fortawesome/pro-solid-svg-icons/faAngleRight";
import { faClock          as fasFaClock          } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faComment        as fasFaComment        } from "@fortawesome/pro-solid-svg-icons/faComment";
import { faFilePdf        as fasFaFilePdf        } from "@fortawesome/pro-solid-svg-icons/faFilePdf";
import { faFolder         as fasFaFolder         } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { faMap            as fasFaMap            } from "@fortawesome/pro-solid-svg-icons/faMap";
import { faQuestionCircle as fasFaQuestionCircle } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faSearch         as fasFaSearch         } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faTag            as fasFaTag            } from "@fortawesome/pro-solid-svg-icons/faTag";
import { faTimes          as fasFaTimes          } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faUserCircle     as fasFaUserCircle     } from "@fortawesome/pro-solid-svg-icons/faUserCircle";

import { faBars           as falFaBars           } from "@fortawesome/pro-light-svg-icons/faBars";

import { faFacebookF      as fabFaFacebookF      } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faLinkedinIn     as fabFaLinkedinIn     } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";

/**
 * Add solid icons
 */
library.add(fasFaAngleDown, fasFaAngleLeft, fasFaAngleRight, fasFaClock, fasFaComment, fasFaFilePdf, fasFaFolder, fasFaMap, fasFaQuestionCircle, fasFaSearch, fasFaTag, fasFaTimes, fasFaUserCircle);

/**
 * Add light icons
 */
library.add(falFaBars);

/**
 * Add brand icons
 */
library.add(fabFaFacebookF, fabFaLinkedinIn);

/**
 * Watch the DOM to insert icons
 */
dom.watch();
